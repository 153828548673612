import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Layout from '../layouts/index'

class BlogIndex extends React.Component {
  wpPosts() {
    const posts = get(this, 'props.data.allWordpressPost.edges')
    return posts.map(({ node }) => {
      const title = get(node, 'title') || node.slug

      const img = node.featured_media ? (
        <GatsbyImage
          image={
            node.frontmatter.featured_media.childImageSharp.gatsbyImageData
          }
        />
      ) : //
      //   <Img
      //   fluid={node.featured_media.localFile.childImageSharp.fluid}
      //   fadeIn={true}
      // />
      null

      return (
        <div
          className="cell  small-10 small-offset-1 medium-8 medium-offset-2"
          key={node.slug}
        >
          <h3>
            <Link to={'/blog/' + node.slug}>{title}</Link>
          </h3>
          {img}
          <small>{node.date}</small>
          <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
        </div>
      )
    })
  }

  rmPosts() {
    const posts = get(this, 'props.data.allMarkdownRemark.edges')
    return posts.map(({ node }) => {
      const title = get(node, 'frontmatter.title') || node.fields.slug

      const img = node.frontmatter.thumbnail ? (
        <GatsbyImage
          image={node.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
        />
      ) : // <Img
      //   fluid={node.frontmatter.thumbnail.childImageSharp.fluid}
      //   fadeIn={true}
      // />
      null

      return (
        <div
          className="cell small-10 small-offset-1 medium-6 medium-offset-3"
          key={node.fields.slug}
        >
          <Link to={node.fields.slug}>{img}</Link>
          <h3>
            <Link to={node.fields.slug}>{title}</Link>
          </h3>
          <small>{node.frontmatter.date}</small>
          <div className="entry-excerpt">
            <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
          </div>
        </div>
      )
    })
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={siteTitle} />

          <div className="pagehead">
            <div className="pagehead__wrap black small" />
          </div>

          <div className="callout white">
            <div className="grid-x">{this.rmPosts()}</div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogQuery {
    site {
      siteMetadata {
        title
      }
    }

    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt

          fields {
            slug
          }

          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            author

            thumbnail {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }

            authorPhoto {
              childImageSharp {
                gatsbyImageData(layout: FIXED)
              }
            }
          }
        }
      }
    }
  }
`
